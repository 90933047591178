import { ActionTypes } from 'utils/constants';

export const blogPosts = (state = {}, action) => {
    switch(action.type) {
        case ActionTypes.FETCH_BLOG_POSTS + '_PENDING': {
            return {
                ...state,
                fetching: true
            };
        }
        case ActionTypes.FETCH_BLOG_POSTS + '_FULFILLED': {
            const posts = action.payload.data.posts;
            
            return {
                ...state,
                fetching: false,
                fetched: true,
                posts
            };
        }
        case ActionTypes.FETCH_BLOG_POSTS + '_REJECTED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: true
            };
        }
        default: {
            return state;
        }
    }
};
