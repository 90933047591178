import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InlineLoader from 'components/_core/InlineLoader';
import Message from 'components/_core/Message';
import { fetchPost } from 'redux/actions/blog';

const AllPostsFetcher = (
    {
        children,
        match: { params: { slug } }
    }
) => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.blogPost.fetched);
    const fetching = useSelector(state => state.blogPost.fetching);
    const error = useSelector(state => state.blogPost.error);
    const fetchedSlug = useSelector(state => state.blogPost.post?.slug);

    useEffect(() => {
        if ((!fetched || slug !== fetchedSlug) && !fetching && !error) {
            dispatch(fetchPost(slug));
        }
    }, [dispatch, fetched, fetching, error, slug, fetchedSlug])

    if (error) {
        return (
            <Message heading="Error loading post" error noIcon small>
                Refresh the page to try again.
            </Message>
        );
    }

    if (!fetched || slug !== fetchedSlug) {
        return <InlineLoader />;
    }

    return children;
};

export default withRouter(AllPostsFetcher);
