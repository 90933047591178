import { ActionTypes } from 'utils/constants';
import axios from 'axios';
import { getEnvVar } from 'utils/get-env-var';

export const fetchPosts = () => (
    {
        type: ActionTypes.FETCH_BLOG_POSTS,
        payload: axios.get(`${getEnvVar('API_BASE')}/blog-posts`)
    }
);

export const fetchPost = slug => (
    {
        type: ActionTypes.FETCH_BLOG_POST,
        payload: axios.get(`${getEnvVar('API_BASE')}/blog-post?slug=${slug}`)
    }
);
