import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import AllPostsFetcher from './AllPostsFetcher';
import { useSelector } from 'react-redux';
import Wrapper from 'components/_core/Wrapper';
import Header from 'components/_core/HeaderLeft';
import LoginMenuItem from 'components/LoginMenuItem';
import SaleBanner from 'components/Home/SaleBanner';
import Footer from 'components/_core/Footer';
import BlogHeader from './BlogHeader';
import Container from 'components/_core/Container';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Paginator from './Paginator';
import { getEnvVar } from 'utils/get-env-var';
import DefaultPhoto from './placeholder.jpg';
import { SignUpBtn } from './SignUpBtn';

const navItems = [
    () => <li><SignUpBtn /></li>,
    () => <li><LoginMenuItem /></li>
];

const PostContainer = styled.div`
    background: rgb(237,237,237);
    background: linear-gradient(180deg, rgba(237,237,237,1) 0%, rgba(255,255,255,1) 300px);

    padding: 0 0 0 0;
    position: relative;
`;


const PostWrapper = styled(Container)`
    background-color: #fff;
    color: #000;
    padding: 40px 30px;
    max-width: 800px;
    margin-top: 5px;

    img, iframe {
        max-width: 100%;
    }

    @media screen and (max-width: 530px) {
        padding: 40px 15px;
    }
`;

const Bar = styled.div`
    width: 100%;
    height: 6px;
`;

const PostInner = styled.div`
    padding: 10px;
`;

const PostGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
`;

const PostImage = styled.div`
    position: relative;
    padding-bottom: 66.6667%;
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const PostMeta = styled.div``;

const ReadLink = styled.span`
    color: ${props => props.theme.colors.orange};
    text-align: right;
    text-decoration: underline;
`;

const Post = styled(Link)`
    flex-basis: 50%;
    color: #000;
    text-decoration: none;

    h2 {
        font-size: 20px;
        font-weight: 600;
    }
    
    h3 {
        font-size: 16px;
        font-weight: 300;
    }

    // 1st and 7th post
    &:nth-child(1),
    &:nth-child(6) {
        flex-basis: 100%;

        ${PostImage} {
            padding-bottom: 45%;
        }

        h2 {
            font-size: 30px;
        }
    }

    @media screen and (max-width: 530px) {
        flex-basis: 100%;

        border-top: 1px solid #eee;
        padding: 10px 0;
        border-bottom: 1px solid #eee;

        &:not(:first-child) {
            ${PostInner} {
                display: flex;
                align-items: center;
                
                flex-direction: row-reverse;
                justify-content: space-between;

                h2 {
                    font-size: 20px;
                }


                ${PostImage} {
                    width: 80px;
                    height: 80px;
                    padding-bottom: 0;
                    flex-shrink: 0;
                    margin-left:5px;
                }

                p {
                    display: none;
                }

                ${ReadLink} {
                    text-align: left;
                }
            }
        }
    }
`;


const Blog = () => {
    const activeSale = useSelector(state => state.sales.sale);
    const hasActiveSaleBanner = activeSale && activeSale.banner_text;

    const [page, setPage] = useState(1);
    const postsPerPage = 10;
    const posts = useSelector(state => state.blogPosts.posts) || [];
    const postsToShow = posts.slice((page - 1) * postsPerPage, page * postsPerPage);
    const numOfPages = Math.ceil(posts.length / postsPerPage);
    
    const onPageChange = useCallback((page) => {
        setPage(page);
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <SaleBanner />
            <Wrapper $hasActiveSaleBanner={hasActiveSaleBanner}>
                <Header hideLogoUntilScroll hasActiveSaleBanner={hasActiveSaleBanner} navItems={navItems} />
            </Wrapper>
            <BlogHeader $hasActiveSaleBanner={hasActiveSaleBanner} />
            <PostContainer>
                <Bar />
                <PostWrapper>
                    <AllPostsFetcher>
                        <PostGrid>
                            {(postsToShow || []).map((post, i) => (
                                <Post key={i} to={`/blog/${post.slug}`}>
                                    <PostInner>
                                        <PostImage><img src={post.photo ? `${getEnvVar('UPLOAD_PATH')}/large_${post.photo}` : DefaultPhoto} alt={post.headline} /></PostImage>
                                        <PostMeta>
                                            <h2>{post.headline}</h2>
                                            {post.teaser && <p>{post.teaser}</p>}
                                            <ReadLink>Read Now</ReadLink>
                                        </PostMeta>
                                    </PostInner>
                                </Post>
                            ))}
                        </PostGrid>
                        <Paginator page={page} totalPages={numOfPages} onPageChange={onPageChange} />
                    </AllPostsFetcher>
                </PostWrapper>
            </PostContainer>
            <Footer />

        </>
    );
};

export default Blog;
