import React from 'react';
import styled from 'styled-components';

import WithScrollPosition from '../hoc/scroll-position-provider';

import Container from './Container';
import FullLogo from './FullLogo';
import InlineNav from './InlineNav';

const HeaderWrapper = styled.div.attrs(props => {
    const scrolled = props.scrollPosition > 50;
    props.backgroundColor = scrolled ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)';

    if (props.hideLogoUntilScroll && !scrolled) {
        props.logoHidden = true;
    }

    props.maxWidth = props.maxWidth || props.theme.containerMaxWidth;
})`
    position: fixed;
    top: ${props => props.hasActiveSaleBanner ? '60px' : '0'};
    left: 0;
    width: 100%;
    z-index: 99999;

    transition: background-color 0.3s;
    background-color: ${props => props.backgroundColor};
    padding: 0 ${props => props.theme.horizontalPadding};

    & > ${Container} {
        display: flex;
        align-items: center;

        max-width: ${props => props.maxWidth};

        padding: 10px 0;

        & > .full-logo {
            transition: all 0.3s;
            overflow: hidden;
            max-width: 300px

            ${props => {
                if (props.logoHidden) {
                    return `
                        opacity: 0;
                        max-width: 0;
                    `;
                }
            }}
        }

        & > ${InlineNav} {
            a, button {
                color: #000;
                text-decoration: none;
                font-weight: 100;
                text-transform: uppercase;
                font-size: 16px;
            }
            button {
                margin-bottom: -2px;
            }

            li {
                margin-right: 45px;
            }
        }
    }

    @media screen and (max-width: 800px) {
        .hide-tablet {
            display: none !important;
        }
    }

    @media screen and (max-width: 530px) {
        .logo-circle {
            width: 50px;
        }
        .logo-text {
            font-size: 16px;
        }

        & > ${Container} {
            & > ${InlineNav} {
                a, button {
                    font-size: 13px;
                }

                ul {
                    padding: 0;
                    margin: 8px 0 16px 0;

                    li {
                        margin-right: 25px;
                    }
                }
            }
        }

        .hide-mobile {
            display: none !important;
        }
    }

    @media screen and (max-width: 370px) {
        .logo-text {
            font-size: 12px;
        }
    }
`;

const Header = props => (
    <HeaderWrapper {...props}>
        <Container>
            <FullLogo light size="small" />
            <InlineNav>
                <ul>
                    {props.navItems.map((Item, i) => <Item key={`nav-item-${i}`} />)}
                </ul>
            </InlineNav>
        </Container>
    </HeaderWrapper>
);

export default WithScrollPosition(Header);