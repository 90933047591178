import { ActionTypes } from 'utils/constants';

export const blogPost = (state = {}, action) => {
    switch(action.type) {
        case ActionTypes.FETCH_BLOG_POST + '_PENDING': {
            return {
                ...state,
                fetching: true
            };
        }
        case ActionTypes.FETCH_BLOG_POST + '_FULFILLED': {
            const post = action.payload.data;
            
            return {
                ...state,
                fetching: false,
                fetched: true,
                post
            };
        }
        case ActionTypes.FETCH_BLOG_POST + '_REJECTED': {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: true
            };
        }
        default: {
            return state;
        }
    }
};
