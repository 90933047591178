import React from 'react';
import { useSelector } from 'react-redux';
import { ButtonLink } from 'components/_core/Button';
import { TRIAL_DAYS } from 'utils/constants';

export const SignUpBtn = () => {
    const isSubscriber = useSelector(state => state.user.userData.isSubscriber);

    if (isSubscriber) {
        return null;
    }

    return (
        <ButtonLink className="btn" orange bold pill small style={{ color: '#fff' }} to="/">{TRIAL_DAYS > 0 ? '7 Days Free' : 'Join Now'}</ButtonLink>
    );
};
