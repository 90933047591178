import React from 'react';
import styled from 'styled-components';

const PaginatorContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
`;
const PageButton = styled.button`
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
`;
const PageInfo = styled.div`
    margin: 0 10px;
    font-size: 12px;
`;

const Paginator = ({ page, totalPages, onPageChange }) => {
    const handlePageChange = (newPage) => {
        if (newPage !== page) {
            onPageChange(newPage);
        }
    };

    if (totalPages <= 1) return null;

    return (
        <PaginatorContainer>
            <PageButton disabled={page === 1} onClick={() => handlePageChange(page - 1)}>
                Previous
            </PageButton>
            <PageInfo>
                Page {page} of {totalPages}
            </PageInfo>
            <PageButton disabled={page === totalPages} onClick={() => handlePageChange(page + 1)}>
                Next
            </PageButton>
        </PaginatorContainer>
    );
};

export default Paginator;
