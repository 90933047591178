import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InlineLoader from 'components/_core/InlineLoader';
import Message from 'components/_core/Message';
import { fetchResources } from 'redux/actions/resources';

const ResourceFetcher = (
    {
        children
    }
) => {
    const dispatch = useDispatch();
    const fetched = useSelector(state => state.resources.fetched);
    const error = useSelector(state => state.resources.error);

    useEffect(() => {
        dispatch(fetchResources());
    }, [dispatch])

    if (error) {
        return (
            <Message heading="Error loading courses" error noIcon small>
                Refresh the page to try again.
            </Message>
        );
    }

    if (!fetched) {
        return <InlineLoader />;
    }

    return children;
};

export default ResourceFetcher;
