import React, { useState } from 'react';
import { faFilePdf, faFileVideo, faLink, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

import ResourceFetcher from './ResourceFetcher';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ListView, ListViewItem } from 'components/_core/ListView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEnvVar } from 'utils/get-env-var';

const AccodianWrap = styled.div`
    margin-bottom: 30px;
`;
const AccordianHeader = styled.div`
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
`;
const AccordianCarret = styled(FontAwesomeIcon)`
    margin-right: 10px;
    font-size: 0.8em;
`;

const ResourceListViewItem = styled(ListViewItem)`
    display: flex;
    align-items: center;

    cursor: pointer;

    &:hover {
        background-color: #333;
    }
`;
const ResourceIcon = styled(FontAwesomeIcon)`
    margin-right: 10px;
    font-size: 1.2em;
`;

const VideoPop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    background-color: rgba(0,0,0,0.85);

    iframe {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 90%;
        max-height: 90%;
    }
`;

const CloseBtn = styled.div`
    position: fixed;
    top: 0px;
    right: 20px;
    font-size: 40px;
    color: #fff;
    cursor: pointer;
`;


const formatCategoryName = name => {
    return name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()).replace('Pdf', 'PDF');
};

const VideoPlayer = ({ videoId, stop }) => {   
    if (!videoId) {
        return null;
    }

    const src = `https://player.vimeo.com/video/${videoId}`;

    return (
        <VideoPop onClick={() => stop()}>
            <iframe 
                width={1000} 
                height={563}
                src={src + "?autoplay=1"} 
                frameBorder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
                title="JPBouvet Method Video"
            ></iframe>
            <CloseBtn>&times;</CloseBtn>
        </VideoPop>
    );
};

const ResourceAccordian = ({ resources, title, open }) => {
    const [isOpen, setIsOpen] = useState(open);
    const [playingVideo, setPlayingVideo] = useState(null);

    if (!resources || !resources.length) {
        return null;
    }

    const handleAction = resource => {
        if (resource.type === 'pdf') {
            window.open(`${getEnvVar('PDF_PATH')}/${resource.pdf}`);
        } else if (resource.type === 'link') {
            window.open(resource.link);
        } else {
            setPlayingVideo(resource.vimeo_id);
        }
    };
    
    return ( 
        <>
            <AccodianWrap>
                {title && <AccordianHeader onClick={() => setIsOpen(!isOpen)}>
                    <AccordianCarret icon={isOpen ? faChevronDown : faChevronUp } />
                    {formatCategoryName(title)}
                </AccordianHeader>}
                {isOpen && (
                    <ListView>
                        {resources.map((r, i) => (
                            <ResourceListViewItem key={i} onClick={() => handleAction(r)}>
                                <ResourceIcon icon={r.type === 'pdf' ? faFilePdf : r.type === 'video' ? faFileVideo : faLink} />
                                {r.name}
                            </ResourceListViewItem>
                        ))}
                    </ListView>
                )}
            </AccodianWrap>
            <VideoPlayer videoId={playingVideo} stop={() => setPlayingVideo(null)} />
        </>
    );
};

const Resources = () => {
    const resources = useSelector(state => state.resources.resources);

    return (
        <ResourceFetcher>
            <ResourceAccordian resources={resources?.pinned} open />
            {(resources?.categories || []).map(category => (
                <ResourceAccordian key={category.name} resources={category.resources} title={category.name} open={true} />
            ))}
        </ResourceFetcher>
    );
};

export default Resources;
