import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from "react-helmet";

import Wrapper from 'components/_core/Wrapper';
import Header from 'components/_core/HeaderLeft';
import LoginMenuItem from 'components/LoginMenuItem';
import SaleBanner from 'components/Home/SaleBanner';
import Footer from 'components/_core/Footer';
import SinglePostFetcher from './SinglePostFetcher';
import BlogHeader from './BlogHeader';
import styled from 'styled-components';
import Container from 'components/_core/Container';
import WithScrollPosition from 'components/hoc/scroll-position-provider';
import { getEnvVar } from 'utils/get-env-var';

import AdImg from './ad.png';
import EmailIcon from './share-icons/email-icon.svg';
import FacebookIcon from './share-icons/facebook-icon.svg';
import XIcon from './share-icons/x-icon.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { SignUpBtn } from './SignUpBtn';

const navItems = [
    () => <li><SignUpBtn /></li>,
    () => <li><LoginMenuItem /></li>
];

const ProgressBar = styled.div`
    width: 100%;
    height: 6px;
    position: sticky;
    top: 97px;
    left: 0;

    &::before {
        content: '';
        width: ${props => props.$scrollPercent * 100}%;
        height: 6px;
        background: ${props => props.theme.colors.orange};
        position: absolute;
        top: 0;
        left: 0;
    }

    @media screen and (max-width: 530px) {
        top: 83px;
    }
`;

const PostContainer = styled.div`
    background: rgb(237,237,237);
    background: linear-gradient(180deg, rgba(237,237,237,1) 0%, rgba(255,255,255,1) 300px);

    padding: 0 0 0 0;
    position: relative;
`;


const PostWrapper = styled(Container)`
    background-color: #fff;
    color: #000;
    padding: 40px 30px;
    max-width: 800px;
    margin-top: 5px;

    img, iframe {
        max-width: 100%;
    }

    @media screen and (max-width: 530px) {
        padding: 40px 15px;
    }
`;

const PostHeadline = styled.h1`
    margin: 0;
    margin-bottom: 15px;
    font-size: 35px; 
    font-family: "Libre Caslon Text", serif;
`;
const PostSubHeadline = styled.h2`
    margin: 0;
    margin-bottom: 15px;
    font-size: 18px; 
    font-family: "Libre Caslon Text", serif;
`;
const PostCategory = styled.p`
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
    color: ${props => props.theme.colors.orange};
    font-family: 'Inter', sans-serif;
`;


const PostImage = styled.img`
    max-width: 100%;
    margin: 15px 0;
`;
const PostTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 530px) {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
`;
const PostAd = styled.div``;
const PostTopRight = styled.div`
    flex-grow: 1;
    text-align: right;

    @media screen and (max-width: 530px) {
        text-align: left;
        margin: 0 0 20px 0;
    }
`;
const PostByline = styled.p`
    font-size: 18px;
    font-weight: 800;
    margin: 0;
`;
const PostPublishedDate = styled.p`
    margin: 0;
`;
const PostShare = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;

    position: relative;

    &::before {
        content: 'Share';
        background: #000;
        padding: 5px 10px;
        color: #fff;
        font-size: 12px;
        margin-right: 5px;
    }
`;
const ShareIcon = styled.img`
    width: 20px;
    margin: 0 5px;
    cursor: pointer;
`;

const PostContent = styled.div`
    margin-top: 30px;
    font-size: 17px;
    line-height: 1.3em;
    font-family: 'Open Sans', sans-serif;

    * + h2, * + h3, * + h4 {
        margin-top: 40px;
    }

    h2 {
        font-size: 30px;
        line-height: 1.3em;
    }

    h3 {
        font-size: 25px;
        line-height: 1.3em;
    }

    h4 {
        font-size: 20px;
        line-height: 1.2em;
    }

    a {
        color: ${props => props.theme.colors.orange};
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
`;

const formatDate = date => {
    if (!date) return '';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
};

const share = type => {
    const pageUrl = encodeURIComponent(window.location.href);
    const pageTitle = encodeURIComponent(document.title);
    
    // Define share URLs
    const shareLinks = {
        x: `https://x.com/intent/tweet?url=${pageUrl}&text=${pageTitle}`,
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`,
        email: `mailto:?subject=${pageTitle}&body=Check this out: ${pageUrl}`
    };

    const link = shareLinks[type];
    if (link) {
        window.open(link, '_blank');
    }
};

const SinglePost = (props) => {
    const post = useSelector(state => state.blogPost.post);
    const activeSale = useSelector(state => state.sales.sale);
    const hasActiveSaleBanner = activeSale && activeSale.banner_text;

    // get the scrollable height
    const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
    
    return (
        <>
            <SaleBanner />
            <Wrapper $hasActiveSaleBanner={hasActiveSaleBanner}>
                <Header hideLogoUntilScroll hasActiveSaleBanner={hasActiveSaleBanner} navItems={navItems} />
            </Wrapper>
            <BlogHeader $hasActiveSaleBanner={hasActiveSaleBanner} />
            <PostContainer>
                <ProgressBar $scrollPercent={props.scrollPosition / scrollableHeight} />
                <PostWrapper>
                    <SinglePostFetcher>
                        <Helmet>
                            <title>{post?.page_title || post?.headline}</title>
                            <meta name="description" content={post?.meta_description} />
                        </Helmet>

                        {post?.category && <PostCategory>{post?.category}</PostCategory>}
                        <PostHeadline>{post?.headline}</PostHeadline>
                        {post?.subheadline && <PostSubHeadline>{post?.subheadline}</PostSubHeadline>}
                        {post?.photo && <PostImage src={`${getEnvVar('UPLOAD_PATH')}/large_${post.photo}`} />}

                        <PostTop>
                            <PostAd><Link to="/"><img src={AdImg} alt="Join JpBouvetMethod now" /></Link></PostAd>
                            <PostTopRight>
                                <PostByline>{post?.author}</PostByline>
                                <PostPublishedDate>{formatDate(post?.publish_date)}</PostPublishedDate>
                                <PostShare>
                                    <ShareIcon src={XIcon} alt="X" onClick={() => share('x')} />
                                    <ShareIcon src={FacebookIcon} alt="Facebook" onClick={() => share('facebook')} />
                                    <ShareIcon src={EmailIcon} alt="Email" onClick={() => share('email')} />
                                </PostShare>
                            </PostTopRight>
                        </PostTop>

                        <PostContent dangerouslySetInnerHTML={{ __html: post?.content }} />
                    </SinglePostFetcher>
                </PostWrapper>
            </PostContainer>
            <Footer />

        </>
    );
};

export default WithScrollPosition(SinglePost);
