import React from 'react';

import styled from 'styled-components';
import dwLogo from './drum-wise-logo.png';
import Container from 'components/_core/Container';
import LogoCircle from 'components/_core/LogoCircle';
import { Link } from 'react-router-dom';

const HeaderContainer = styled.div`
    position: relative;
    background-color: #fff;

    ${props => props.$hasActiveSaleBanner && `
        padding-top: 45px;
    `}
`;

const FloatingLogo = styled.div`
    position: absolute;
    bottom: -35px;
    left: 50%;
    margin-left: -30px;
    width: 60px;
    display: block;
    z-index: 2;

    img {
        width: 100%;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: 5px;
        width: calc(100% - 10px);
        height: 100%;
        border-radius: 50%;
        background-color: #fff;
        z-index: -1;
    }
`;

const Content = styled(Container)`
    color: #000;
    padding: 50px 15px 40px 15px;
    text-align: center;

    img, h2, h3 {
        margin: 0 0 4px 0;
    }

    img {
        width: 450px;
        max-width: 100%;
    }

    h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2em;
    }

    h3 {
        font-size: 16px;
        font-weight: 300;
    }

    @media screen and (max-width: 530px) {
        padding-top: 70px;
    }
`;

const BlogHeader = ({ $hasActiveSaleBanner }) => {
    return (
        <HeaderContainer $hasActiveSaleBanner={$hasActiveSaleBanner}>
            <Content>
                <Link to="/blog"><img src={dwLogo} alt="Drum Wise Logo" /></Link>
                <h2>Everything Worth Knowing About the Drums</h2>
                <h3>by JPBouvetMethod.com</h3>
            </Content>
            <FloatingLogo><LogoCircle size="xsmall" /></FloatingLogo>
        </HeaderContainer>
    );
};

export default BlogHeader;
