import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { setMyLearningView } from "redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";




const SwitcherButton = styled.button`
    background: none;
    padding: 0;
    border: none;
    color: #fff;
    margin-right: 20px;
    cursor: pointer;
    padding-bottom: 8px;
    border-bottom: 3px solid transparent;
    display: flex;
    align-items: center;
    font-size: 16px;

    @media screen and (max-width: 450px) {
        font-size: 14px;
    }

    ${props => {
        if (props.active) {
            return `
                color: ${props.theme.colors.orange};
                border-bottom-color: ${props.theme.colors.orange};
            `;
        }
    }}

    @media screen and (max-width: 650px) {   
        font-size: 18px;
        margin: 10px 0;
        font-size: 20px;
                 
        ${props => {
            if (props.active) {
                return `
                    display: block !important;
                    order: 1;
                    text-align: left;
                `;
            } else {
                return `
                    order: 2;
                `;
            }
        }}
    }
`;

const Switcher = styled.div`
    margin: 0 0 30px;
    display: flex; 

    @media screen and (max-width: 650px) {
        flex-direction: column;
        position: relative;

        ${props => {
            if (!props.open) {
                return `
                    ${SwitcherButton} {
                        display: none;
                    }
                `;
            }
        }}
    }
`;

const MobileMenuBtn = styled.button`
    display: none;
    @media screen and (max-width: 650px) {
        display: block;
        position: absolute;
        top: 8px;
        right: 0;

        color: ${props => props.theme.colors.orange};
        background: none;
        font-size: 22px;
        border: none;
        outline: none;
        pointer: cursor;
    }
`;

const MyLearningViewSwitcher = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const isSubscriber = useSelector(state => state.user.userData.isSubscriber);

    const view = useSelector(state => state.courseList.filters.myLearningView);

    const switchView = useCallback(nextView => {
        if (view === nextView) {
            return setOpen(!open);
        }
        dispatch(setMyLearningView(nextView));
        setOpen(false);
    }, [dispatch, view, open]);

    return (
        <Switcher open={open}>
            {/* <SwitcherButton active={view === 'all'} onClick={() => switchView('all')}>All</SwitcherButton> */}
            <SwitcherButton active={view === 'courses'} onClick={() => switchView('courses')}>Courses</SwitcherButton>
            <SwitcherButton active={view === 'guest-courses'} onClick={() => switchView('guest-courses')}>Guest Courses</SwitcherButton>
            <SwitcherButton active={view === 'play-alongs'} onClick={() => switchView('play-alongs')}>Play Alongs</SwitcherButton>
            {isSubscriber && <SwitcherButton active={view === 'favorites'} onClick={() => switchView('favorites')}>Favorites</SwitcherButton>}
            {isSubscriber && <SwitcherButton active={view === 'progress-posts'} onClick={() => switchView('progress-posts')}>Progress Posts</SwitcherButton>}
            {isSubscriber && <SwitcherButton active={view === 'resources'} onClick={() => switchView('resources')}>Resources</SwitcherButton>}
            <MobileMenuBtn onClick={() => setOpen(!open)}><FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} /></MobileMenuBtn>
        </Switcher>
    );
};

export default MyLearningViewSwitcher;
