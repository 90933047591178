import { combineReducers } from 'redux';

import courseList from './course-list';
import currentCourse from './current-course';
import user from './user';
import { contentStats } from './content-stats';
import { sales } from './sales';
import { resources } from './resources';
import { toastNotifications } from './toast-notifications';
import { impact } from './impact';
import { blogPosts } from './blog';
import { blogPost } from './blog-post';

export default combineReducers({
    courseList,
    currentCourse,
    user,
    contentStats,
    sales,
    resources,
    toastNotifications,
    impact,
    blogPosts,
    blogPost
});
